<template>
  <div id="blog">
    <div class="root_cont yellow_bg">
      <div class="container">
        <h2 class="text-center" v-if="is_first_page">BLOG</h2>
        <div id="dib-posts" ref="blog_cont"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Blog",
  data() {
    return {
      is_first_page: true
    };
  },
  mounted() {
    const vm = this;

    window.dib_id = "YW7ODD0TCSVKD35OPMG5";
    loadScriptMaybe();
    vm.is_first_page = checkIfFirstPage();
    if (vm.is_first_page) vm.handleFirstPage()

    function checkIfFirstPage() {
      if (window.location.href.indexOf("?") === -1) return true
      else if (window.location.href.indexOf("?page") !== -1) return true
      else return false
    }

    function loadScriptMaybe() {
      if (document.querySelector("#dropinblog_script")) return
      const script = document.createElement("script");
      script.setAttribute("src", "https://dropinblog.com/js/embed.js");
      script.setAttribute("id", "dropinblog_script");
      script.async = true;

      document.body.appendChild(script);
    }

  },
  methods: {
    async handleFirstPage() {
      const vm = this;

      vm.$el.style.display = "none";
      if (window.dropinblog_first_page_innerHTML) {
        vm.$refs.blog_cont.innerHTML = window.dropinblog_first_page_innerHTML
        filterPostByLang();
        vm.$el.style.display = "block";
      } else {
        await waitForBlogFill()
        window.dropinblog_first_page_innerHTML = vm.$refs.blog_cont.innerHTML;
        filterPostByLang()
        vm.$el.style.display = "block";
      }

      function waitForBlogFill() {
        return new Promise(resolve => {
          check()
          function check() {
            const posts = vm.$el.querySelectorAll("a.dib-post");
            if (posts.length === 0) setTimeout(check, 200);
            else resolve()
          }
        })
      }

      function filterPostByLang() {
        const posts = vm.$el.querySelectorAll("a.dib-post");
        let lang = vm.$lang;
        if (lang === "es") lang = "en"
        const langcode = "lang" + lang

        posts.forEach(post => {
          const href = post.getAttribute("href");
          if (href.indexOf(langcode) === -1) post.remove()
        });
      }
    }
  }
};
</script>
<style>
#blog .root_cont {
  padding: 40px 0;
}

#blog #dib-posts {
}

#blog #dib-posts h2 {
  font-size: 1.25rem;
}

#blog #dib-template-2 .dib-post-text h2 {
  color: rgb(4, 43, 93);
}

#blog #dib-template-2 .dib-post-text p.dib-category-text {
  margin-top: 1.2em;
}
#blog #dib-template-2 .dib-post-text {
  padding-bottom: 5px;
}
</style>
